<template>
  <div class="card__item strong_team flex flex-col justify-center">
    <img :src="img" class="h-12" :alt="imgAlt" />
    <div class="section__title mt-4 mb-0 md:text-lg">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ["img", "imgAlt", "title"]
};
</script>

<style scoped lang="scss">
.card__item {
  .section__title {
    font-size: 0.6875rem;
  }
  &:last-child {
    grid-column: 1 / span 2;
  }
}

@media screen and (min-width: 992px) {
  .card__item {
    .section__title {
      font-size: 0.875rem;
    }
    &::before {
      content: "";
      position: absolute;
      margin-left: -1.5rem;
      width: 2.9375rem;
      border: 1px solid rgba(3, 56, 77, 0.14);
      transform: rotate(90deg);
    }
    &:first-child::before {
      content: "";
      border: none;
    }
    &:last-child {
      grid-column: 3/4;
    }
  }
}
</style>
