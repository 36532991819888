<template>
  <section class="container about" id="about">
    <div
      class="about__card relative grid grid-cols-2 py-8 px-6 z-40 bg-white rounded-xl
    sm:w-4/5 sm:mx-auto md:grid-cols-3 md:mx-auto md:gap-0"
    >
      <StrengthItem v-for="strength in strengths" :key="strength.id" :img="strength.img" :imgAlt="strength.imgAlt" :title="strength.title"></StrengthItem>
    </div>
    <h1 class="heading uppercase text-center text-secondary font-extrabold	text-base tracking-widest mb-4">
      <!-- About Us -->
      {{ $t("about.title") }}
    </h1>
    <div class="about__slider relative">
      <VueSlickCarousel class="slide sm:w-11/12 sm:mx-auto" v-bind="settings">
        <div class="slide-1 p-4" v-for="slide in slides" :key="slide.id">
          <div class="slide relative">
            <header class="flex items-center justify-center py-10 md:py-4 md:-mt-6 md:mb-4">
              <h1 class="text-dark text-center text-xl sm:text-3xl md:text-6xl font-extrabold leading-relaxed w-9/12 lg:w-full mt-3 sm:mt-0">
                {{ slide.title }}
              </h1>
            </header>
            <div class="slide__body md:grid md:grid-cols-2">
              <div class="flex justify-center">
                <img :src="slide.slideImg" :alt="slide.slideImgAlt" class="slide__img sm:w-full rounded-2xl max-h-72 w-auto object-cover" draggable="false" />
              </div>
              <div class="slide__content text-dark mt-10 md:mt-0 md:p-4 md:pl-14 sm:pl-4 lg:leading-23">
                <p class="mb-4" v-html="slide.slideContent_p1"></p>
                <p v-html="slide.slideContent_p2"></p>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import StrengthItem from "./StrengthItem.vue";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "component.about",
  components: {
    VueSlickCarousel,
    StrengthItem
  },
  data() {
    return {
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        // cssEase: 'cubic-bezier(.84, 0, .08, .99)',
        cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
        infinite: true,
        arrows: true,
        dots: true,
        speed: 940,
        accessibility: true,
        // autoplay: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true
        // autoplaySpeed: 7000
      },
    };
  },
  mounted() {
    document.querySelector(".slick-prev").innerHTML =
      '<svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57-.001-33.94z"/></svg>';
    document.querySelector(".slick-next").innerHTML =
      '<svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M285.47 272.97L91.12 467.31c-9.38 9.37-24.57 9.37-33.95 0L34.5 444.64c-9.357-9.36-9.375-24.53-.04-33.91l154.02-154.75L34.45 101.23c-9.34-9.38-9.32-24.55.04-33.91l22.67-22.67c9.37-9.38 24.56-9.38 33.94 0l194.34 194.34c9.37 9.37 9.37 24.56 0 33.94z"/></svg>';
  },
  created() {},
  beforeDestroy() {},
  computed: {
    slides(){
      return  [
        {
          id: 1,
          slideImg: require("@/assets/img/specialities/bitcoin_payments.webp"),
          slideImgAlt: this.$t("about.slide_1.slideImgAlt"),
          title:  this.$t("about.slide_1.title"),
          slideContent_p1: this.$t("about.slide_1.slideContent_p1"),
          slideContent_p2:  this.$t("about.slide_1.slideContent_p2"),
        },
        {
          id: 2,
          slideImg: require("@/assets/img/specialities/fintech.webp"),
          slideImgAlt: this.$t("about.slide_2.slideImgAlt"),
          title:  this.$t("about.slide_2.title"),
          slideContent_p1: this.$t("about.slide_2.slideContent_p1"),
          slideContent_p2:  this.$t("about.slide_2.slideContent_p2"),
        },
        {
          id: 3,
          slideImg: require("@/assets/img/specialities/data_analytics.webp"),
          slideImgAlt: this.$t("about.slide_3.slideImgAlt"),
          title:  this.$t("about.slide_3.title"),
          slideContent_p1: this.$t("about.slide_3.slideContent_p1"),
          slideContent_p2:  this.$t("about.slide_3.slideContent_p2"),
        },
        {
          id: 4,
          slideImg: require("@/assets/img/specialities/business_intellligence_ai.webp"),
          slideImgAlt: this.$t("about.slide_4.slideImgAlt"),
          title:  this.$t("about.slide_4.title"),
          slideContent_p1: this.$t("about.slide_4.slideContent_p1"),
          slideContent_p2:  this.$t("about.slide_4.slideContent_p2"),
        }
      ]
    },
    strengths(){
      return [
        {
          id: 1,
          img: require("@/assets/img/strengths/happy_clients.svg"),
          imgAlt: this.$t("strengths.item_1.imgAlt"),
          title:  this.$t("strengths.item_1.title")
        },
        {
          id: 2,
          img: require("@/assets/img/strengths/support.svg"),
          imgAlt: this.$t("strengths.item_2.imgAlt"),
          title:  this.$t("strengths.item_2.title")
        },
        {
          id: 3,
          img: require("@/assets/img/strengths/fast_growth.svg"),
          imgAlt: this.$t("strengths.item_3.imgAlt"),
          title:  this.$t("strengths.item_3.title")
        }
      ]
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
::v-deep .about {
  &__card {
    box-shadow: 10px 4px 30px rgba(4, 57, 78, 0.08);
    top: -3.8125rem;
    grid-gap: 1.125rem 2rem;
  }
  &_slider {
    .slide__title {
      color: #03384d;
    }
  }
}

/********************************

SLICK STYLES

*********************************/
[class*="slide-"]:focus {
  outline: none;
  border: none;
}

::v-deep .slick-prev:before,
::v-deep .slick-next:before {
  display: none;
}

::v-deep .slick-next,
::v-deep .slick-prev {
  svg {
    fill: #d9034798;
    &:hover {
      fill: #d90347;
    }
  }
}

::v-deep button.slick-prev,
::v-deep button.slick-next {
  top: 56px;
  height: 40px;
  transform: translateY(50%);
}

@media (min-width: 992px) {
  ::v-deep button.slick-prev,
  ::v-deep button.slick-next {
    top: -0.5rem;
  }
}

::v-deep .slick-prev {
  left: 20px;
  z-index: 40;
}

::v-deep .slick-next {
  right: 20px;
}

::v-deep .slick-dots li {
  width: 10px;
}

::v-deep .slick-dots li button:before {
  font-size: 8px;
}

::v-deep .slick-dots li.slick-active button:before {
  color: #d90346;
}

/********************************

MEDIA QUERIES

*********************************/

@media screen and (min-width: 640px) {
  ::v-deep .slick-prev,
  ::v-deep .slick-next {
    top: 8%;
  }
}

@media screen and (min-width: 992px) {
  ::v-deep .about {
    &__card {
      width: 86.585%;
    }
  }
}

/* iPad Pro (portrait) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header {
    padding: 0;
    margin-bottom: 2rem;
  }
}
</style>
