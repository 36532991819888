<template>
  <div id="app" class="">
    <Navbar />
    <main>
      <Showcase />
      <About />
      <Services />
      <Projects />
      <Contact />
      <Footer />
    </main>
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import Showcase from "./components/Showcase";
import About from "./components/About";
import Services from "./components/Services";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

export default {
  name: "app",
  components: {
    Navbar,
    Showcase,
    About,
    Services,
    Projects,
    Contact,
    Footer,
  },
  created() {
    document.title = this.$t("page_seo.title").toString()
    const description = this.$t("page_seo.description").toString()
    document.querySelector('meta[name="description"]').setAttribute("content", description);
    const keywords =this.$t("page_seo.keywords").toString()
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
  },
  beforeDestroy() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap");

.font-montserrat {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/* Some Global Styles */
.section__title {
  @apply uppercase text-center  font-bold	text-base tracking-widest mb-4;
}

@media screen and (min-width: 992px) {
  .section__title {
    font-size: 0.6875rem;
  }
}
</style>
