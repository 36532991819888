<template>
  <section class="showcase z-0" id="showcase">

    <VueSlickCarousel class="slide relative border-0 outline-none" v-bind="settings">
      <showcaseSlide
        v-for="slide in slides"
        :key="slide.id"
        :slideId="slide.id"
        :title="slide.title"
        :para="slide.paragraph"
        :cta="slide.cta"
        :bgImage="slide.bgImg"
      />
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import showcaseSlide from "./ShowcaseSlide";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "component.showcase",
  components: {
    VueSlickCarousel,
    showcaseSlide
  },
  data() {
    return {
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        fade: true,
        speed: 1000,
        infinite: true,
        arrows: false,
        dots: true,
        accessibility: true,
        autoplay: true,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true,
        autoplaySpeed: 8000,
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  computed: {
    slides(){
      return [
        {
          id: 1,
          title: this.$t("showcase.slide_1.title"),
          paragraph: this.$t("showcase.slide_1.paragraph"),
          bgImg: require("../assets/img/showcase/brainy.webp")
        },
        {
          id: 2,
          title: this.$t("showcase.slide_2.title"),
          paragraph: this.$t("showcase.slide_2.paragraph"),
          bgImg: require("@/assets/img/showcase/ai.webp")
        },
        {
          id: 3,
          title: this.$t("showcase.slide_3.title"),
          paragraph: this.$t("showcase.slide_3.paragraph"),
          bgImg: require("../assets/img/showcase/binary.webp")
        },
        {
          id: 4,
          title: this.$t("showcase.slide_4.title"),
          paragraph: this.$t("showcase.slide_4.paragraph"),
          bgImg: require("@/assets/img/showcase/servers.webp")
        }
      ]
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
/********************************

Carousel Styles

*********************************/
[class*="slide-"]:focus {
  outline: none;
  border: none;
}

::v-deep .slick-dots {
  margin-bottom: 98px;
}

::v-deep .carousel .slick-dots {
  position: absolute;
  bottom: 10px;
  z-index: 999;
}

::v-deep .slick-dots li {
  width: 10px;
}

::v-deep .slick-dots li button:before {
  opacity: 1;
  color: #fff;
}

::v-deep .slick-dots li.slick-active button:before {
  color: #d90346;
  transform: scale(1.15) !important;
}
</style>
