<template>
  <nav id="navbar" class="navbar fixed top-0 left-0 w-full bg-transparent z-50 md:overflow-hidden" v-bind:class="{ 'nav-active': isHidden === true }">
    <div class="container relative flex justify-between items-center">
      <a href="#showcase" class="navbar__logo" v-smooth-scroll>
        <img src="../assets/img/logo.png" class="h-10 lg:h-12" alt="Talentech Consulting" />
      </a>

      <!------------------------

        BURGER MENU

      ------------------------>
      <div id="nav-toggler" v-on:click="isHidden = !isHidden" class="navbar__toggler flex justify-center items-center md:hidden w-6 h-6 z-50">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <!-------------------------------

        NAVBAR MENU ON DESKTOP SCREEN

      --------------------------------->
      <ul class="navbar__list hidden md:flex">
        <li class="navbar__list-item">
          <a href="#showcase" class="navbar__list-link" v-smooth-scroll>{{ $t("navbar.item_1") }}</a>
        </li>
        <li class="navbar__list-item">
          <a href="#about" class="navbar__list-link" v-smooth-scroll="{ offset: -70 }"> {{ $t("navbar.item_2") }}</a>
        </li>
        <li class="navbar__list-item">
          <a href="#services" class="navbar__list-link" v-smooth-scroll>{{ $t("navbar.item_3") }}</a>
        </li>
        <li class="navbar__list-item">
          <a href="#projects" class="navbar__list-link" v-smooth-scroll="{ offset: -20 }">{{ $t("navbar.item_4") }}</a>
        </li>
      </ul>
    </div>

    <!-------------------------------

      NAVBAR MENU ON MOBILE SCREEN

    --------------------------------->
    <transition
      enter-active-class="transition-all transition-fastest ease-out-quad"
      leave-active-class="transition-all transition-faster ease-in-quad"
      enter-class="opacity-0 scale-70"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-70"
    >
      <div v-if="isHidden" class="sm-menu origin-top-right shadow-md z-50 md:hidden nav-active">
        <ul class="sm-menu px-2 pb-2 space-y-1 pt-10 m-0 p-0">
          <li v-on:click="closeNav">
            <a href="#showcase" class="menu-link" v-smooth-scroll>Home</a>
          </li>
          <li v-on:click="closeNav">
            <a href="#about" class="menu-link" v-smooth-scroll="{ offset: -70 }">About Us</a>
          </li>
          <li v-on:click="closeNav">
            <a href="#services" class="menu-link" v-smooth-scroll>Services</a>
          </li>
          <li v-on:click="closeNav">
            <a href="#projects" class="menu-link" v-smooth-scroll="{ offset: -20 }">Projects</a>
          </li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
export default {
  name: "component.navbar",
  components: {},
  data() {
    return {
      isHidden: false
    };
  },
  created() {},
  mounted() {
    window.addEventListener("resize", () => {
      /* removes `.nav-active` when switching mob and desktop screen */
      this.isHidden = false;
    });

    let firstScrollPos = Math.abs(window.pageYOffset);

    window.addEventListener("scroll", () => {
      let secondScrollPos = Math.abs(window.pageYOffset);

      // console.log(secondScrollPos, firstScrollPos);

      if (firstScrollPos > secondScrollPos) {
        document.getElementById("navbar").style.top = "0px";
        if (secondScrollPos === 0) {
          document.getElementById("navbar").style.background = "transparent";
        } else {
          document.getElementById("navbar").style.background = "#06384d";
        }
      } else {
        document.getElementById("navbar").style.top = "-75px";
      }

      firstScrollPos = secondScrollPos;
    });

    /* Open Burger Menu */
    document.querySelector("#nav-toggler").addEventListener("click", function() {
      this.classList.toggle("open");
    });
  },
  beforeDestroy() {},
  computed: {},
  methods: {
    // showMenu() {
    //   this.isHidden !== this.isHidden;
    // },
    closeNav() {
      /* Close navbar on close */
      document.querySelector("#nav-toggler").classList.remove("open");
      document.querySelector(".navbar").classList.remove("nav-active");
      document.querySelector(".sm-menu").style.visibility = "hidden";
      this.isHidden = false;
    }
  }
};
</script>

<style scoped lang="scss">
$navHeight: 75px;

.navbar {
  height: $navHeight;
  transition: top 0.2s ease-in-out;
  &__list {
    &-link {
      @apply px-6 transition-all transition-medium block text-gray-300 font-semibold tracking-wide;
    }
  }
  .container {
    height: inherit;
  }
  &__logo {
    img {
      //width: 12.25rem;
    }
  }
  .menu-link {
    @apply text-gray-300 block text-center px-3 py-2 rounded-sm text-base font-medium;
    &:hover {
      background-color: #093f55;
      border-radius: 0.5rem;
    }
  }
}

.nav-active {
  background: #06384d !important; // overwrite bg color on scrollW
  border-bottom: 1px solid #124f67;
}

/********************************

NAVBAR TRANSITIONS AND ANIMATIONS

*********************************/
.origin-top-right {
  transform-origin: top right;
}
.transition-all {
  transition-property: all;
}
.transition-fastest {
  transition-duration: 50ms;
}
.transition-faster {
  transition-duration: 100ms;
}
.transition-fast {
  transition-duration: 150ms;
}
.transition-medium {
  transition-duration: 200ms;
}
.ease-out-quad {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ease-in-quad {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.scale-70 {
  transform: scale(0.7);
}
.scale-100 {
  transform: scale(1);
}

/* BURGER MENU */
#nav-toggler {
  width: 18px;
  height: 15px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 1px;
    width: 50%;
    background: #fff;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }

    &:nth-child(odd) {
      left: 0;
      border-radius: 9px 0 0 9px;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 0;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 4px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 8px;
    }

    &:nth-child(7),
    &:nth-child(8) {
      top: 12px;
    }
  }

  &.open span:nth-child(1),
  &.open span:nth-child(7) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2),
  &.open span:nth-child(8) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 2px;
    top: 3px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 1px);
    top: 3px;
  }

  &.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: -50%;
    opacity: 0;
  }

  &.open span:nth-child(6) {
    left: 100%;
    opacity: 0;
  }

  &.open span:nth-child(7) {
    left: 8px;
    top: 9px;
    transform: rotate(45deg);
  }

  &.open span:nth-child(8) {
    left: 2px;
    top: 9px;
    transform: rotate(-45deg);
  }
}

@media screen and (min-width: 992px) {
  .navbar {
    height: $navHeight;
    &__list {
      border-bottom: 1px solid #124f67;
      &-link {
        position: relative;
        line-height: 74px;
        height: 74px;
        line-height: 74px;
        &:before {
          content: "";
          position: absolute;
          height: 2px;
          background-color: rgba(209, 213, 219, 0.494);
          bottom: 0;
          width: 100%;
          left: 50%;
          transform: translateX(-50%) scale(0);
          transition: transform 0.5s;
        }
        &:hover {
          background: rgba(75, 254, 254, 0.06);
        }
        &:hover:before {
          transform: translateX(-50%) scale(1);
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 60%;
      right: 0;
      width: 50%;
      border-bottom: 1px solid #124f67;
    }
  }
}
</style>
