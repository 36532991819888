import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import vueSmoothScroll from "vue2-smooth-scroll";
import showcaseSlide from "./components/ShowcaseSlide";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: "G-LRQZG34RMG",
    params: {
      send_page_view: true
    }
  }
});

Vue.config.productionTip = false;

// CSS
import "@/assets/css/app.css";
// import "@/assets/css/style.css";
// import "@fortawesome/vue-fontawesome";
// import "@fortawesome/fontawesome-svg-core";
require("vue2-animate/dist/vue2-animate.min.css");

// App
Vue.use(vueSmoothScroll);
Vue.use(showcaseSlide);

new Vue({
  i18n,
  render: h => h(App)
}).$mount("#app");
