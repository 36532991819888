<template>
  <div :class="'slide-' + slideId" class="relative bg-no-repeat bg-cover m-0 border-none outline-none">
    <div class="container mx-auto ">
      <div class="card absolute top-1/4 px-1 sm:ml-14 sm:w-2/4">
        <div class="card__title mb-4">
          <h1 class="text-white text-4xl font-extrabold leading-48 md:text-4rem md:leading-76">
            {{ title }}
          </h1>
        </div>
        <div class="card__content text-white leading-23 md:leading-30">
          <p class="font-normal text-sm md:text-lg">
            {{ para }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "component.showcase_slide",
  props: ["slideId", "title", "para", "cta", "bgImage"],
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
/* Mobile First Styles */
[class*="slide-"] {
  height: 550px;
}

.slide-1 {
  background-image: linear-gradient(to left bottom, rgba(#06384d, 0.4), rgba(#0b2a8c, 0.7)), url("../assets/img/showcase/brainy.webp");
  background-position: 55% center;

  .btn-cta {
    $red: #d90346;
    background: lighten($red, 3%);
    box-shadow: 0px 2px 0 darken($red, 1%), 2px 2px 6px darken($red, 1%);
    transition: all 150ms linear;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 5rem;
      width: 100%;
      height: 104%;
      transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
      animation: fadeIn 0.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &:hover {
      background: darken($red, 1.5%);
      box-shadow: 1px 1px 2px rgba(#fff, 0.3);
      text-decoration: none;
      text-shadow: -2px -2px 0 darken($red, 9%);
      transition: all 250ms linear;

      &::after {
        border: 1px solid rgba(255, 255, 255, 0.179);
        top: -30%;
        left: -20%;
        width: 140%;
        height: 160%;
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        animation: fadeOut 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }

      &::before {
        top: -60%;
        left: -40%;
        width: 180%;
        height: 220%;
        transition-delay: 0.2s;
        transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
        animation: fadeOut 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      border: 1px solid rgba(255, 255, 255, 0.179);
    }
    100% {
      opacity: 0;
      border: 1px solid rgba(255, 255, 255, 0.309);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 1;
      border: 1px solid rgba(255, 255, 255, 0.035);
    }
    100% {
      opacity: 0.2;
      border: 1px solid rgba(255, 255, 255, 0.159);
    }
  }
}

.slide-2 {
  background-image: linear-gradient(to left bottom, rgba(#06384d, 0.4), rgba(#0b2a8c, 0.7)), url("../assets/img/showcase/ai.webp");
  background-position: center;
  background-size: cover;
}

.slide-3 {
  background-image: linear-gradient(to left bottom, rgba(#06384d, 0.4), rgba(#06258a, 0.8)), url("../assets/img/showcase/binary.webp");
  background-size: cover;
}

.slide-4 {
  background-image: linear-gradient(to left bottom, rgba(#06384d, 0.2), rgba(#06258a, 1)), url("../assets/img/showcase/servers.webp");
  background-size: cover;
}

/********************************

Responsive Design

*********************************/
@media screen and (min-width: 992px) {
  .showcase,
  [class*="slide-"] {
    height: 750px;

    .card {
      width: 27.75rem;
    }
  }

  .slide-1 {
    background-size: cover;

    .card {
      top: 25%;

      &__title {
        animation: animateLeft 1.5s ease-out 0.5s;
        animation-fill-mode: backwards;
      }

      &__content {
        animation: animateRight 1.5s ease-out 0.5s;
        animation-fill-mode: backwards;
      }

      .btn-cta {
        animation: animateTop 1.5s ease-in 1s;
        animation-fill-mode: backwards;
      }
    }
  }
  ::v-deep .slick-dots {
    margin-bottom: 7.125rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 639px) {
  .slide-1 {
    .card {
      width: 75%;
    }
  }
}

@media (min-width: 1824px) {
  .slide-1 {
    background-size: cover;

    .card {
      top: 32%;
    }
  }
}

/* Ipad Pro (Portrait) */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .slide-1 {
    background-size: cover;
    background-position: 73% 100%;

    .card {
      top: 35%;
    }
  }
}

/* Ipad Pro (landscape) */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .slide-1 {
    background-size: cover;

    .card {
      top: 30%;
    }
  }
}

/* iPad (landscape) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .slide-1 {
    background-size: cover;

    .card {
      &__title {
        h1 {
          font-size: 3rem !important;
          line-height: 1.3;
        }
      }

      &__content {
        .btn-cta {
          padding: 0.5rem 1.75rem;
        }
      }
    }
  }
}

/* iPad (portrait)  */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .showcase,
  [class*="slide-"] {
    height: 600px;
  }
  .slide-1 {
    .card {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

/* animations */
@keyframes animateRight {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  80% {
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes animateLeft {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  80% {
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes animateTop {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  70% {
    transform: translateY(5px);
  }
  85% {
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
