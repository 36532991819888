<template>
  <section class="services relative my-14 bg-no-repeat md:bg-left" id="services">
    <h1 class="heading section__title">
      {{ $t("services.heading_1") }}
    </h1>
    <header>
      <h1 class="text-dark text-center text-4xl mb-10 font-extrabold leading-relaxed">{{ $t("services.heading_2") }}</h1>
    </header>
    <div class="container">
      <div class="container-mini cards grid grid-cols-1 sm:grid-cols-2 sm:w-10/12 sm:mx-auto md:grid-cols-3 md:gap-4">
        <service-card v-for="service in services" :key="service.id" :icon="service.icon" :title="service.title" :content="service.content"></service-card>
      </div>
    </div>
  </section>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";

export default {
  name: "component.services",
  components: {"service-card": ServiceCard},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
    services() {
      return [
        {
          id: 1,
          icon: require("@/assets/img/services/business_software.svg"),
          title: this.$t("services.item_1.title"),
          content: this.$t("services.item_1.content")
        },
        {
          id: 2,
          icon: require("@/assets/img/services/bi_software.svg"),
          title: this.$t("services.item_2.title"),
          content: this.$t("services.item_2.content")
        },
        {
          id: 3,
          icon: require("@/assets/img/services/ai_and_ml.svg"),
          title: this.$t("services.item_3.title"),
          content: this.$t("services.item_3.content")
        },
        {
          id: 4,
          icon: require("@/assets/img/services/blockchain_defi.svg"),
          title: this.$t("services.item_4.title"),
          content: this.$t("services.item_4.content")
        },
        {
          id: 5,
          icon: require("@/assets/img/services/personal_service.svg"),
          title: this.$t("services.item_5.title"),
          content: this.$t("services.item_5.content")
        },
        {
          id: 6,
          icon: require("@/assets/img/services/multilingual.svg"),
          title: this.$t("services.item_6.title"),
          content: this.$t("services.item_6.content")
        }
      ]
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.services {
  background-image: url("../assets/img/services/pattern-sm.svg");
  background-size: contain;
  background-position: 0% -155%;
}

@media screen and (min-width: 992px) {
  .container-mini {
    width: 95%;
  }
  .services {
    background-image: url("../assets/img/services/pattern.svg");
    background-size: 100% 100%;

    .heading {
      font-size: 1.125rem;
    }

    .cards {
      grid-auto-rows: 26.5rem;
    }

    .card {
      border: 2px solid #f7dee7;
    }
  }
}

/* iPad (portrait)  */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .services {
    background-image: url("../assets/img/services/pattern.svg");
    background-size: 100% 100%;
    background-position: center;
  }
}
</style>
