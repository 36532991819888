<template>
  <div class="card p-8 m-4 rounded-3xl bg-white">
    <div class="card__header">
      <img :src="icon" alt="" />
      <h1 class="text-dark text-base font-bold mt-6 mb-2">{{ title }}</h1>
    </div>
    <div class="card__body leading-23">
      <p class="text-dark text-xs" v-html="content">
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "title", "content"]
};
</script>

<style scoped lang="scss">
.card {
  box-shadow: 10px 4px 30px rgba(4, 57, 78, 0.08);
}

/* iPad Pro (portrait) */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .card {
    margin: 0;
  }
}

/* iPad (LandScape) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .card {
    margin: 0;
  }
}
</style>
