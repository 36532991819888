<template>
  <footer class="bg-dark text-center pb-0 p-4 md:p-0 z-50">
    <div class="container f-top grid grid-cols-1 gap-6 md:grid-cols-3">
      <div class="flex flex-col justify-center">
        <img class="logo" src="../assets/img/contact_footer/logo.svg" alt="Talentech Consulting" />
        <div class="flex justify-center gap-x-2">
          <img @click="setLang('es')" class="langLogo" src="../assets/img/es.svg" alt="">
          <img @click="setLang('en')" class="langLogo" src="../assets/img/gb.svg" alt="">
        </div>
      </div>

      <div class="location flex justify-center items-center flex-col md:flex-row">
        <img src="../assets/img/contact_footer/location.svg" class="h-8 mt-2 md:h-14 md:mr-4" alt="location svg" />
        <p class="text-white mt-3 text-sm md:text-left md:text-lg">Ricardo Soriano 21, 29601 Marbella, Spain</p>
      </div>

      <nav class="leading-7 md:grid md:grid-cols-2">
        <ul class="menu_1 text-white flex flex-col text-center md:justify-center m-0 p-0">
          <li>
            <a href="#showcase" v-smooth-scroll data-hover="Home" class="relative inline-block text-white">{{ $t("navbar.item_1") }}</a>
          </li>
          <li>
            <a href="#about" v-smooth-scroll="{ offset: -70 }" data-hover="About Us" class="relative inline-block text-white">{{ $t("navbar.item_2") }}</a>
          </li>
        </ul>
        <ul class="menu_2 text-white flex flex-col md:justify-center m-0 p-0">
          <li>
            <a href="#services" v-smooth-scroll="{ offset: -30 }" data-hover="Services" class="relative inline-block text-white">{{ $t("navbar.item_3") }}</a>
          </li>
          <li>
            <a href="#projects" v-smooth-scroll="{ offset: -20 }" data-hover="Projects" class="relative inline-block text-white">{{ $t("navbar.item_4") }}</a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="text-gray-300 text-sm text-center -mt-5 pb-3">
      <a v-if="$i18n.locale === 'es'" target="_blank" href="/bitcoin_es.pdf">{{ $t("bitcoin_paper") }}</a>
      <a v-else target="_blank" href="/bitcoin.pdf">{{ $t("bitcoin_paper") }}</a>
    </div>
    <div class="text-gray-300 text-sm text-center -mt-3 pb-3">
      <a target="_blank" href="/lightning-network-paper.pdf">The Bitcoin Lightning Network: Scalable Off-Chain Instant Payments</a>
    </div>
    <div class="container f-bottom text-gray-400 text-sm mt-8 p-3 ">
      Copyright &copy; {{currentYear}} All rights reserved.
    </div>
  </footer>
</template>

<script>

export default {
  name: "component.footer",
  components: {},
  data() {
    return { langs: ['es', 'en'] }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  computed: {
    currentYear(){
      return new Date().getFullYear()
    }
  },
  methods: {
    setLang(lang) {
      this.$i18n.locale = lang

      document.title = this.$t("page_seo.title").toString()
      const description = this.$t("page_seo.description").toString()
      document.querySelector('meta[name="description"]').setAttribute("content", description);
      const keywords =this.$t("page_seo.keywords").toString()
      document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    }
  }
};
</script>

<style scoped lang="scss">

footer {
  .f-top {
    padding-bottom: 2.43rem;

    .langLogo {
      width: 2.3rem;
      border-radius: 5px;
      cursor: pointer;
    }

    .logo {
      height: 8rem;
    }
    nav {
      .menu_1,
      .menu_2 {
        a {
          transition: 0.5s;
          &::before {
            content: attr(data-hover);
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.5s;
            color: #d90346;
            max-width: 0%;
            overflow: hidden;
            white-space: nowrap;
          }
          &:hover {
            color: rgba(255, 255, 255, 0.034);
          }
          &:hover::before {
            max-width: 100%;
          }
        }
      }
    }
  }
  .f-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0);
    padding: 13px 0 11px 0;
  }
}

@media screen and (min-width: 992px) {
  footer {

    .f-top {
      padding: 75px 0;
      .location {
        //line-height: 2.5rem;
      }
      nav {
        .menu_1,
        .menu_2 {
          margin-top: 0.5rem;
          line-height: 2.5;
        }
      }
    }
    .f-bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.18);
      padding: 1.125rem 0 1.25rem 0;
    }
  }
}
</style>
