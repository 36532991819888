<template>
  <section class="contact bg-red bg-no-repeat bg-cover md:relative md:mt-32" id="contact">
    <div class="container px-4 grid grid-cols-1	gap-14 md:left-2/4 md:grid-cols-2 md:gap-6 md:absolute md:h-full">
      <div class="left-side md:flex md:flex-col md:justify-center p-4">
        <h1 class="font-extrabold	text-white mt-14 mb-6 text-3xl md:text-3r">
          {{ $t("contact.heading_1") }}
        </h1>
        <div class="uppercase text-xs	text-white">{{ $t("contact.heading_2") }}</div>
        <div class="email text-white mt-2 text-lg">
          <span v-html="spamFree"></span>
          <span class="block text-xs mt-1 text-gray-200"> <strong>PGP:</strong> D7D4 9C6F F59D D0B2 34FF  CCD9 3167 E475 B934 E9DA</span>
        </div>
      </div>
      <div class="right-side md:absolute right-0 bottom-0 md:px-4">
        <img src="../assets/img/contact_footer/contact_img.webp" class="sm-hidden md:w-contactImg md:h-contactImg w-full" alt="Company" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "component.contact",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  computed: {
    spamFree(){
        const dom='&#x74;&#x61;&#x6C;&#x65;&#x6E;&#x74;&#x65;&#x63;&#x68;.&#x65;&#x75;';
        const display='&#x69;&#x6E;&#x66;&#x6F;&#x40;&#x74;&#x61;&#x6C;&#x65;&#x6E;&#x74;&#x65;&#x63;&#x68;.&#x65;&#x75;';
      const x40='&#x40;';
      const part1='&#x6D;&#x61;&#x69;&#x6C;&#x74;&#x6F;:';
      const name='&#x69;&#x6E;&#x66;&#x6F;';
      const stuff='';
      return `<a title="${display}" href="${part1}${name}${x40}${dom}${stuff}">${display}</a>`
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.contact {
  background-image: url("../assets/img/contact_footer/pattern.svg");
  min-height: 506px;
  .container {
    padding: 0;
    .left-side {
      h1 {
        letter-spacing: 2px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .contact {
    max-height: 506px;
    .container {
      transform: translateX(-50%);
    }
  }
}

</style>
