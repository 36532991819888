<template>
  <section id="projects">
    <header class="lg:mb-2">
      <h1 class="section__title uppercase text-lg mb-0">
        {{ $t("projects.heading_1") }}
      </h1>

      <h1 class="text-dark font-montserrat text-center text-3xl lg:text-4xl mb-10 font-extrabold leading-relaxed">{{ $t("projects.heading_2") }}</h1>
    </header>
    <div class="container px-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-4 my-8">
        <div
          class="project shadow-md rounded-lg z-10"
          v-for="project in projects"
          :key="project.id"
          v-bind:style="{ backgroundImage: 'url(' + project.img_src + ')' }"
          :class="{ 'bg-full-size': project.id === 9, border: project.id === 4 }"
        >
          <div class="overlay flex flex-col justify-end items-center text-center text-gray-200 bg-gray-300 rounded-md pb-4">
            <a :href="project.url" target="_blank" class="btn bg-gray-100  p-2 text-sm rounded-md hover:border hover:border-red-500">
              <svg clip-rule="evenodd" fill-rule="evenodd" height="24" width="24" xmlns="http://www.w3.org/2000/svg" fill="#0a0a0a">
                <path
                  d="m14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464s-2.56-.488-3.535-1.464c-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464s-2.56-.488-3.535-1.464c-.494-.495-.863-1.067-1.107-1.678z"
                />
              </svg>
            </a>
            <h3 class="text-gray-200 text-sm uppercase font-bold mt-2">{{ project.project_name }}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "component.projects",
  data() {
    return {
      projects: [
        {
          id: 1,
          project_name: "YvoeFrance",
          img_src: require("@/assets/img/projects/yvoefrance.webp"),
          url: "https://yvoefrance.com"
        },
        {
          id: 2,
          project_name: "Brapago Payments",
          img_src: require("@/assets/img/projects/brapago.webp"),
          url: "https://brapago.com"
        },
        {
          id: 3,
          project_name: "EasyRemessa",
          img_src: require("@/assets/img/projects/easyremessa.webp"),
          url: "https://easyremessa.com"
        },
        {
          id: 4,
          project_name: "Eurofed France",
          img_src: require("@/assets/img/projects/eurofed1.webp"),
          url: "https://eurofed.eu"
        },
        {
          id: 7,
          project_name: "Ventunix Consulting",
          img_src: require("@/assets/img/projects/ventunix.webp"),
          url: "https://ventunix.com"
        },
        {
          id: 5,
          project_name: "VoucherPay",
          img_src: require("@/assets/img/projects/voucherpay.webp"),
          url: "https://www.voucherpay.com.br"
        },
        {
          id: 6,
          project_name: "Coinance",
          img_src: require("@/assets/img/projects/coinance.webp"),
          url: "https://coinance.eu"
        },
        {
          id: 8,
          project_name: "WebCheque",
          img_src: require("@/assets/img/projects/webcheque.jpg"),
          url: "https://webcheque.com.br"
        },
        {
          id: 9,
          project_name: "AlgoFxOnline",
          img_src: require("@/assets/img/projects/algofxonline.webp"),
          url: "https://algofxonline.com"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.bg-full-size {
  background-size: 100% !important;
}

.project {
  position: relative;
  width: 100%;
  height: 200px;
  background-size: 140%;
  background-repeat: no-repeat;
  transition: background-position-y 1s ease-in-out, transform 1s ease-out;
  transform-origin: center;
  background-position: top center;
  overflow: hidden;
  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: scale(3);
    opacity: 0;
    background: rgba($color: #000000, $alpha: 0);
    h3,
    a,
    svg {
      opacity: 0;
      position: relative;
      top: 1rem;
    }
    svg {
      top: 0rem;
    }
    .btn {
      background-image: linear-gradient(to right, #ece9e6 0%, #ffffff 30%, #dddddd 100%);
      margin: 10px;
      padding: 0.75rem;
      transition: 0.5s;
      background-size: 200% auto;
      color: white;
      box-shadow: 0 0 5px #eee;
      border-radius: 10px;
      display: block;
      &:hover {
        background-position: left;
        color: #fff;
        box-shadow: 0 0 0px #eee;
        text-decoration: none;
      }
      &:active {
        transform: scale(0.9);
        box-shadow: none;
      }
    }
  }
  &:hover {
    background-size: 200%;
    background-image: cover;

    transition: background-position-y 4s ease-out, transform 500ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    z-index: 20;
    overflow: none;
    .overlay {
      transform: scale(1);
      opacity: 1;
      background-image: radial-gradient(circle farthest-corner at 12.3% 19.3%, #06384d 0%, #095a7c 100.2%);
      transition: transform 300ms ease-in, background 500ms ease-in;
      h3,
      a,
      svg {
        opacity: 1;
        transition: opacity 1s ease-out;
      }
    }
  }
}
</style>
